/**
  The styles in this file will only be applied when running the app standalone, i.e. before the migration to Micro Frontends.
*/

@use '@matillion/component-library';

* {
  margin: 0;
  padding: 0;
}
